import { HiBell,HiBellAlert } from "react-icons/hi2";
import '../user.css'

const UserProfile = () =>{
    return(
        <div className="w-screen h-screen flex flex-col items-center justify-start relative bg-white">
            <div className="blue-circle"></div>
            <div className="flex justify-between p-4 mb-4">
                <div className='flex flex-col w-full p-3 h-auto'>
                    
                </div>
            </div>
        </div>
    )
}

export default UserProfile;
 